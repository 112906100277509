.alert {
    max-height: 8em;
    max-width: 28em;
    background-color: #D0E4F1;
    border-color: #64A6D0;

}

@media (max-width: 500px) {
    .alert {
        max-height: 15em;
        max-width: 90%
    }
}