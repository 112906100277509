.accordion {
    --bs-accordion-active-bg: lightgray !important;
    --bs-accordion-bg: #2F719D;
    width: 80%;
    margin-top: 1em;
}

/* Accordion button con stato 'collapsed' (chiuso) */
.accordion-button.collapsed {
    color: white;
    background-color: #2F719D;  /* Colore di sfondo quando è chiuso */
    font-weight: bold;
}

/* Accordion button con stato 'collapsed' (aperto) */
.accordion-button:not(.collapsed) {
    color: #2F719D;  /* Colore del testo quando è aperto */
    background-color: white;  /* Colore di sfondo quando è aperto */
    font-weight: bold;
}

.accordion-body {
    background-color: white !important;
}

.accordion-button:focus {
    box-shadow: var(--accordion-btn-focus-box-shadow);
}

/* Se vuoi rimuovere completamente l'ombreggiatura, imposta la variabile su 'none' */
:root {
    --accordion-btn-focus-box-shadow: none;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23032041FF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}



@media (max-width: 900px) {
    .accordion{
        width: 95%;
    }

}
