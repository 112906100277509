.sottotitolo-title-container {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 6em;
    line-height: 1.8em;
    word-wrap: break-word;
    display: flex;
    align-items: center; /* Centra verticalmente */
}

.sottotitolo-title {
    font-size: 3em;
    font-weight: bold;
    margin: 0;
    white-space: normal;
    color: #65A7D0;
    font-family: "Hammersmith One";
    text-transform: uppercase;
    display: inline;
}

@media (max-width: 800px) {
    .sottotitolo-title {
        font-size: 2em;
        padding: 0.5em;
    }
    .sottotitolo-title-container {
        max-width: 100%;
    }
}
