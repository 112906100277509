.carousel-item.my-carousel-home img {
    width: 100%;
    height: 600px; /* Altezza desiderata per le immagini */
    object-fit: cover; /* Taglia l'immagine in modo uniforme per adattarla all'area definita */
    object-position: center 20%;

}
.carousel-caption.my-carousel-title {
    width: 10em;
    font-family: sans-serif;
    font-weight: bold;
    border-left: solid;
    border-bottom: solid;
    margin-bottom: 2em;
    border-width: 8px;
    font-size: 70px;
    font-family: "Hammersmith One";
}



.carousel.my-carousel-home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
}

.overlay {
    position: absolute;

    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Overlay nero con opacità 0.5 */
}
.titolo-container-home {
    width: 100%;
    overflow: hidden;
}

.titolo-section-home {
    position: relative;
    height: 600px; /* Regola l'altezza in base alle tue esigenze */
    background-size: cover;
    background-position: center;
    color: white;
    font-family: "Hammersmith One";
    text-transform: uppercase;
}

@media (max-width: 780px) {
    .carousel-item.my-carousel-home img {
        max-height: 250px;
        object-position: center 20%;
    }

    .carousel.my-carousel-home {
        max-height: 250px;
    }
    .carousel-caption.my-carousel-title {
        width: 11em;
        font-family: sans-serif;
        font-weight: bold;
        border-left: solid;
        border-bottom: solid;
        margin-bottom: 2em;
        border-width: 5px;
        font-size: 20px;
    }
    .titolo-section-home {
        position: relative;
        height: 250px; /* Regola l'altezza in base alle tue esigenze */
        background-size: cover;
        background-position: center;
        color: white;
    }
}