.mappa {
    width: 750px;
    height: 500px;
    margin-bottom: 1em;
    padding: 1em;
}



@media (max-width: 400px) {
    .mappa {
        width: 300px;
        height: 250px
    }
}