.header-cell {
    background-color: #F3F8FC !important; /* Sostituisci #yourColor con il colore desiderato */
    color: #fff; /* Cambia il colore del testo se necessario */
    text-align: center;
}

.data-cell {
    /* O qualsiasi altra percentuale che desideri */
    text-align: center; /* Centra il testo all'interno delle celle */
    min-width: 2em;
    font-size: 16px;
    vertical-align: middle;
}

.data-lg {
    background-color: #FFAEA5 !important;
}
.data-mv {
    background-color: #CCE2CB !important;
}

.fascia-a {
    background-color: #f9dcc4 !important;
}

.fascia-b {
    background-color: #fad2e1 !important;
}

.fascia-c {
    background-color: #d7e3fc !important;
}

.vasca-piccola {
    background-color: #FFAEA5 !important;
}

.vasca-grande {
    background-color: #61dafb !important;
}

@media (max-width: 767px) {
    .data-cell {
        /* Riduci il font-size per le celle di dati su dispositivi a schermo piccolo */
        font-size: 12px;
    }
}

.table-container {
    overflow-x: auto;
}
