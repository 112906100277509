.card-multitessere-text {
    position: absolute;
    top: 84%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    fontSize: 24px;
    fontWeight: bold;
    textAlign: center;
    width: 100%;
}

.card-multitessere {
    position: relative;
    max-width: 25em;
    cursor: pointer;
    padding: 0.5em;
}

@media (max-width: 600px) {
    .card-multitessere {
        position: relative;
        max-width: 60%;
        cursor: pointer;
        padding: 0.5em;
    }
}