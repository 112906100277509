.carousel-item.my-carousel img {
    width: 100%;
    height: 600px; /* Altezza desiderata per le immagini */
    object-fit: cover; /* Taglia l'immagine in modo uniforme per adattarla all'area definita */
    border-radius: 1em;
    max-width: 60em;
    object-position: center; /* Centra l'immagine orizzontalmente e la solleva del 20% rispetto all'asse verticale */
}


.carousel.my-carousel {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra l'elemento lungo l'asse orizzontale */
    width: 100%;
    height: 600px;
    max-width: 60em;
    border-radius: 1em;
}

@media (max-width: 600px) {
    .carousel-item.my-carousel img {
        max-height: 250px;
        object-position: center 20%; /* Centra l'immagine orizzontalmente e la solleva del 20% rispetto all'asse verticale */
    }

    .carousel.my-carousel {
        max-height: 250px;
    }
}
