.titolo-container {
    width: 100%;
    overflow: hidden;
}

.titolo-section {
    position: relative;
    height: 400px; /* Regola l'altezza in base alle tue esigenze */
    background-size: cover;
    background-position: center;
    color: white;
    font-family: "Hammersmith One";
    text-transform: uppercase;
}

.overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Overlay nero con opacità 0.5 */
}

.section-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.titolo-title {
    font-size: 5em; /* Dimensione del font iniziale, regola secondo le tue preferenze */
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .titolo-section {
        position: relative;
        height: 150px; /* Regola l'altezza in base alle tue esigenze */
        background-size: cover;
        background-position: center;
        color: white;
    }
    .titolo-title {
        font-size: 2em;
        white-space: normal; /* Permette il wrapping del testo su più righe */
    }
}
