.custom-btn {
    color: white; /* colore del testo */

    border-color: #447aa0; /* colore del bordo */
    color: #447aa0;
}

.custom-btn:hover {
    color: #fff; /* colore del testo al passaggio del mouse */
    background-color: #447aa0; /* colore di sfondo al passaggio del mouse */
    border-color: #447aa0; /* colore del bordo al passaggio del mouse */
}
.custom-btn.show
{
    color: #fff; /* colore del testo al passaggio del mouse */
    background-color: #447aa0; /* colore di sfondo al passaggio del mouse */
    border-color: #447aa0;
}


