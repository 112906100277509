.card-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 5px;

}
.img-thumbnail{
    padding: 0;
    cursor: pointer;

}

@media (max-width: 600px) {
    .img-thumbnail-container {
        width: 100%;
        margin: 0 auto;
    }
    .card-container {
        flex-direction: column;
    }
}
