.viewer360 {
    max-height: 8em;


}

@media (max-width: 500px) {
    .viewer360 {
        max-height: 15em;
    }
}