
.container-fluid{
    --bs-gutter-x: 0
}
.navbar {
    --bs-breakpoint-xxl: 1265px;
}

.navbar {
    font-size: 18px;
}

.nav-item {
    padding-right: 1em;
}

.navbar-collapse {
    background-color: #2F719D; /* Imposta il colore di sfondo del menu responsivo */
    padding: 10px; /* Aggiungi spaziatura per una migliore leggibilità */
}

.navbar-collapse a {
    color: #fff; /* Imposta il colore del testo dei link nel menu responsivo */
}

/* Modifica il colore del bottone del menu (hamburger) quando è attivo */
.navbar-toggler-icon {
    background-color:#2F719D;
}
.navshow {
    overflow-y: auto;
    max-height: 80vh;
}

.dropdown-menu {
    --bs-dropdown-link-active-color: #64A6D0;
    --bs-dropdown-link-hover-color: #64A6D0
}
/* File CSS personalizzato */
.dropdown-menu a {
    color: #ffffff; /* Cambia il colore del testo dei link nel menu dropdown */;
}

.dropdown-menu a:hover {
    color: #64A6D0; /* Cambia il colore del testo dei link al passaggio del mouse */
}

/* File CSS personalizzato */
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active {
    background-color: #2F719D; /* Imposta il colore di sfondo per le opzioni selezionate */
    color: #fff; /* Imposta il colore del testo per le opzioni selezionate */
}
/* File CSS personalizzato */
.dropdown-item.active {
    background-color: #2F719D !important; /* Imposta il colore di sfondo per l'opzione selezionata */
    color: #fff !important; /* Imposta il colore del testo per l'opzione selezionata */
}

/* Sovrascrivi il colore del testo delle voci di menu al passaggio del mouse */
.navbar-nav .nav-link:hover,
.navbar-nav .nav-item:hover .nav-link {
    color: black !important; /* Usa !important per assicurarti che questa regola venga applicata */
}



/* Aggiungi queste regole CSS al tuo file Bar.css */

